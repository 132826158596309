import React from 'react'
import { graphql } from 'gatsby'
import HeroSlider from '../components/HeroSlider'
import TeamList from '../components/TeamList'
import Expertise from '../components/Expertise'
import ContactCTA from '../components/ContactCTA'
import CTABlock from '../components/CTABlock'
import GoldenCircle from '../components/GoldenCircle'
import TextWithoutSlider from '../components/TextWithoutSlider'
import Layout from '../components/Layout'

import './style.scss'
import MassiveTitle from '../components/MassiveTitle/index'
import SEO from '../components/SEO/index'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  render() {
    const { data, location } = this.props

    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="position-relative">
          <HeroSlider compact slides={data.page.frontmatter.slides} />
          <CTABlock data={data.page.frontmatter.cta_block} />
        </div>
        <div className="c-about-container container position-relative pt-3 pt-md-5">
          <div className="u-pull-up position-relative d-sm-none d-md-block">
            <MassiveTitle modifiers={['top']}>About us</MassiveTitle>
          </div>
        </div>
        <div
          className="container position-relative mb-sm-3 mb-lg-5"
          dangerouslySetInnerHTML={{ __html: data.page.html }}
        />
        <GoldenCircle />
        <TextWithoutSlider data={data.page.frontmatter.TextWithSlider.node} />
        <div
          className="container position-relative mb-sm-3 mb-lg-5"
          dangerouslySetInnerHTML={{ __html: data.page.frontmatter.TextWithSlider2.node.html }}
        />
        <Expertise data={data.expertise} />
        <TeamList data={data.team} textData={data.team_text} />
        <ContactCTA />
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}

export default Index

export const aboutQuery = graphql`
  query AboutQuery {
    ...expertise
    ...team


    page: markdownRemark(fields: { slug: { eq: "/about/" } }) {
      ...SEO
      html
      id
      ...ctaBlock
      ...ctaBanner
      frontmatter {
        slider_images {
          file {
            id
            childImageSharp {
              landscape: fluid(maxWidth: 750, maxHeight: 512) {
                ...GatsbyImageSharpFluid_withWebp
              }
              portrait: fluid(maxWidth: 480, maxHeight: 586) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          description
        }

        slides {
          node: childMarkdownRemark {
            html
            id
            frontmatter {
              teaser_title
              short_title
              long_title
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 1440, maxHeight: 700) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              type
              link {
                label
                to
              }
            }
          }
        }
        TextWithSlider {
          node: childMarkdownRemark {
            html

            id
            frontmatter {
              slider_images {
                file {
                  id
                  childImageSharp {
                    landscape: fluid(maxWidth: 750, maxHeight: 512) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    portrait: fluid(maxWidth: 480, maxHeight: 586) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                description
              }
            }
          }
        }
        TextWithSlider2 {
          node: childMarkdownRemark {
            html

            id
            frontmatter {
              slider_images {
                file {
                  id
                  childImageSharp {
                    landscape: fluid(maxWidth: 750, maxHeight: 512) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                    portrait: fluid(maxWidth: 480, maxHeight: 586) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                description
              }
            }
          }
        }
      }
    }
  }
`
