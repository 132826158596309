import React, { Component } from 'react'
import Posed, { PoseGroup } from 'react-pose'

import Img from 'gatsby-image'

import './style.scss'

import Timer from '../Timer'
import {Link} from "gatsby";
import {Subscribe} from "unstated";
import AppContainer from "../AppContainer";

export default class HeroSlider extends Component {
  constructor(props) {
    super(props)

    this.slides = props.slides

    this.slideDuration = 20000 // in ms
    this.slideStandStill = 500
    this.slideTimeout = null
    this.countdownTimeout = null

    this.updateIndex = this.updateIndex.bind(this)
    this.nextSlide = this.nextSlide.bind(this)
    this.prevSlide = this.prevSlide.bind(this)
    this.startCountDown = this.startCountDown.bind(this)
    this.checkWidth = this.checkWidth.bind(this)
    this.compact = props.compact
  }

  state = {
    currentIndex: 0,
    progress: 0,
    maxItems: this.props.slides.length,
    compact: this.compact,
  }

  // change compact prop/state on narrow viewports
  checkWidth() {
    this.setState({
      compact: this.compact && window.matchMedia('(min-width: 577px)').matches,
    })
  }

  componentDidMount() {
    this.startCountDown()
    window.addEventListener('resize', this.checkWidth)
    this.checkWidth()
  }

  componentWillUnmount() {
    this.stopCountDown()
    this.countdownTimeout && clearTimeout(this.countdownTimeout)
    window.removeEventListener('resize', this.checkWidth)
  }

  componentDidUpdate(prevProps, prevState) {
    // check if we've navigated to a new slide, if yes start the countdown again
    // needs to have a timeout to allow the timer animation to reset
    if (prevState.currentIndex != this.state.currentIndex) {
      this.countdownTimeout && clearTimeout(this.countdownTimeout)
      this.countdownTimeout = setTimeout(
        this.startCountDown,
        this.slideStandStill
      )
    }
  }

  nextSlide() {
    let nextIndex = this.state.currentIndex + 1
    nextIndex < this.state.maxItems || (nextIndex = 0)
    this.setState({ currentIndex: nextIndex, progress: 0 })
  }

  prevSlide() {
    let nextIndex = this.state.currentIndex - 1
    nextIndex > 0 || (nextIndex = this.state.maxItems - 1)
    this.setState({ currentIndex: nextIndex, progress: 0 })
  }

  updateIndex(index) {
    this.setState({ currentIndex: index, progress: 0 })
  }

  stopCountDown() {
    this.slideTimeout &&
      clearTimeout(this.slideTimeout) &&
      (this.slideTimeout = null)
  }

  startCountDown() {
    this.setState({ progress: 100 })
    this.slideTimeout && clearTimeout(this.slideTimeout)
    this.slideTimeout = setTimeout(this.nextSlide, this.slideDuration)
  }

  render() {
    const { slides } = this.props
    const { compact } = this.state
    const currentSlide = slides[this.state.currentIndex].node
    return (
      <div className="c-hero-slider-wrapper">
        <section
          className={`c-hero-slider c-hero-slider--${compact && 'compact'}`}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`c-hero-slider__slide ${index ===
                this.state.currentIndex && 'is-active'}`}
            >
              <Img
                style={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
                fluid={slide.node.frontmatter.image.childImageSharp.fluid}
              />
            </div>
          ))}

          <div className="c-hero-slider__content container position-relative pb-5 pb-sm-3  d-flex flex-column h-100">
            <div className="d-flex col">
              <SlideText id={currentSlide.id} data={currentSlide} />
            </div>
            {this.slides.length > 1 && !compact && (
              <SlidePager
                updateIndex={this.updateIndex}
                slides={slides}
                currentIndex={this.state.currentIndex}
                prevIndex={this.prevSlide}
                nextIndex={this.nextSlide}
                progress={this.state.progress}
                slideDuration={this.slideDuration}
              />
            )}
          </div>
        </section>
        {this.slides.length > 1 && compact && (
          <div className="container  py-3 ">
            <SlidePager
              updateIndex={this.updateIndex}
              slides={slides}
              currentIndex={this.state.currentIndex}
              prevIndex={this.prevSlide}
              nextIndex={this.nextSlide}
              darkMode
              progress={this.state.progress}
              slideDuration={this.slideDuration}
            />
          </div>
        )}
      </div>
    )
  }
}

const ArticlePose = Posed.article({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 50,
    opacity: 0,
  },
})

const SlideText = ({ data, id }) => {
  return (
    <PoseGroup animateOnMount={true}>
      <ArticlePose
        key={id}
        className="c-hero-slide-content py-3 py-md-5 text-white my-auto pr-3 pr-sm-0"
      >
        <div className="">
          <h2 className="c-hero-slide-content__title">
            {data.frontmatter.short_title}
          </h2>
          <div
            className="c-hero-slide-content__text"
            dangerouslySetInnerHTML={{ __html: data.html }}
          />
          <footer className="mt-2">
              {data.frontmatter.link.to !== 'gartner_form' ? (
            <a
              href={data.frontmatter.link.to}
              className={"c-read-more text-uppercase"+ (data.frontmatter.link.label ? ' c-button c-button--fill-green' :'') }
            >
              {data.frontmatter.link.label}
            </a>
      ) : (
        <Subscribe to={[AppContainer]}>
          {appContainer => (
             <button
             onClick={appContainer.openGartnerModal}
              className={"c-read-more text-uppercase"+ (data.frontmatter.link.label ? ' c-button c-button--fill-green' :'') }
            >
              {data.frontmatter.link.label}
            </button>
          )}
        </Subscribe>
      )
     }

          </footer>
        </div>
      </ArticlePose>
    </PoseGroup>
  )
}

const SlidePager = ({
  slides,
  currentIndex,
  darkMode,
  updateIndex,
  prevIndex,
  nextIndex,
  progress,
  slideDuration,
}) => (
  <div className="c-hero-slider__pagination">
    {/* mobile nav */}
    <div className="c-hero-slider-mobile-nav d-md-none position-absolute">
      <div className="container">
        <div className="px-2 d-flex">
          <button onClick={prevIndex} className="c-hero-slider-nav-button">
            <Timer progress={0} darkMode={darkMode} arrowPrev />
          </button>
          <button onClick={nextIndex} className="c-hero-slider-nav-button">
            <Timer
              progress={progress}
              duration={slideDuration}
              darkMode={darkMode}
              arrowNext
            />
          </button>
        </div>
      </div>
    </div>
    {/* fullnav */}
    <div className="row d-none d-md-flex">
      {slides.map((slide, index) => (
        <div className="col-sm-3" key={index}>
          <button
            className="c-hero-slider-nav-button"
            key={slide.node.id}
            onClick={() => {
              updateIndex(index)
            }}
          >
            <Timer
              number={index + 1}
              progress={(index === currentIndex && 100) || 0}
              darkMode={darkMode}
              duration={slideDuration}
            />
            <div className="c-hero-slider-nav-button__label">
              <div
                className={`c-hero-slider-nav-button__teaser-title ${darkMode &&
                  'text-primary'}`}
              >
                {slide.node.frontmatter.teaser_title}
              </div>
              <div
                className={`c-hero-slider-nav-button__title ${darkMode &&
                  'text-dark'}`}
              >
                {slide.node.frontmatter.long_title}
              </div>
            </div>
          </button>
        </div>
      ))}
    </div>
  </div>
)
