import React from 'react'

import { ReactComponent as Icon } from '../../gfx/speech-bubble.svg'
import { Subscribe } from 'unstated'
import AppContainer from '../AppContainer'
import MassiveTitle from '../MassiveTitle'
import PropTypes from "prop-types";
import {graphql, Link} from "gatsby";
import CTABanner from "../CTABanner";

const ContactCTA = props => {
  const { data } = props
  const { title, subTitle, linkLabel, link } = data || props
  return (
    <Subscribe to={[AppContainer]}>
      {appContainer => (
        <section>
          <div className="container text-center   pb-3 pb-lg-5 position-relative">
            <div className="w-75 m-auto pt-3 pt-md-0 ">

              <div className="position-relative">
                {' '}
               {link == 'contact_form' ? (
                <button
                  onClick={appContainer.openContactModal}
                  className="c-button c-button--fill-green c-button--large text-uppercase d-inline-flex align-items-center"
                >
                  <span className="c-button__label mr-2">{linkLabel}</span>
                  <Icon />
                </button>
              ) : (link.startsWith('https://') || link.startsWith('http://')) ? ( <a href={link} className="c-button c-button--fill-green c-button--large text-uppercase d-inline-flex align-items-center">
                  <span className="c-button__label mr-2">{linkLabel}</span>
                  <Icon />
                </a>): (
                <Link to={link} className="c-button c-button--fill-green c-button--large text-uppercase d-inline-flex align-items-center">
                  <span className="c-button__label mr-2">{linkLabel}</span>
                  <Icon />
                </Link>
              )}
                <h2 className="px-sm-5 mt-3 h1">
                  {title}
                </h2>
                <div className="text-mid">
                  {subTitle}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Subscribe>
  )
}

export default ContactCTA

ContactCTA.defaultProps = {
  title: 'Talk to us about how you can turn your data into a system to deliver success',
  subTitle: 'Our core expertise is in business-driven applications of predictive analytics and data science to solve complex business challenges which directly impact the bottom line.',
  linkLabel: 'Contact us',
  link: 'contact_form',
}

ContactCTA.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  linkLabel: PropTypes.string,
  link: PropTypes.string,
}

export const ContactCTAQuery = graphql`
  fragment contactCTA on MarkdownRemark {
    frontmatter {
      contact_cta {
        title
        subTitle
        linkLabel
        link
      }
    }
  }
  fragment contactCTAMDX on Mdx {
    frontmatter {
      contact_cta {
        title
        subTitle
        linkLabel
        link
      }
    }
  }
`
